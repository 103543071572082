import { useEffect, useState } from "react"

// Extend the Window interface to include gtag
declare global {
  interface Window {
    gtag?: (...args: any[]) => void
    dataLayer?: any[]
  }
}
import styled from "styled-components"

const CookiePopup = () => {
  const [showPopup, setShowPopup] = useState(false)
  const [, setConsent] = useState<boolean | null>(null)

  useEffect(() => {
    const savedConsent = document.cookie
      .split("; ")
      .find((row) => row.startsWith("cookie_consent="))
      ?.split("=")[1]

    if (savedConsent === "true") {
      setConsent(true)
    } else if (savedConsent === "false") {
      setConsent(false)
    } else {
      setShowPopup(true)
    }
  }, [])

  const acceptCookies = () => {
    document.cookie = "cookie_consent=true; path=/; max-age=" + 60 * 60 * 24 * 365 + "; SameSite=Lax"
    setConsent(true)
    setShowPopup(false)
    loadGoogleAnalytics()
  }

  const declineCookies = () => {
    document.cookie = "cookie_consent=false; path=/; max-age=" + 60 * 60 * 24 * 365 + "; SameSite=Lax"
    setConsent(false)
    setShowPopup(false)
  }

  function loadGoogleAnalytics() {
    if (!window.gtag) {
      const script = document.createElement("script")
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-6NPCWE1TWL"
      script.async = true
      document.head.appendChild(script)

      script.onload = function () {
        window.dataLayer = window.dataLayer || []
        function gtag(...args: any[]) {
          dataLayer.push(args)
        }
        window.gtag = gtag
        gtag("js", new Date())
        gtag("config", "G-6NPCWE1TWL", { anonymize_ip: true })
      }
    }
  }

  if (!showPopup) return null

  return (
    <PopupContainer>
      <Popup>
        <PopupText>
          Pour offrir les meilleures expériences, nous utilisons des technologies telles que les cookies pour stocker et/ou accéder aux informations
          des appareils. Le fait de consentir à ces technologies nous permettra de traiter des données telles que le comportement de navigation ou les
          ID uniques sur ce site.{" "}
        </PopupText>
        <ButtonGroup>
          <AcceptButton onClick={acceptCookies}>Accepter</AcceptButton>
          <DeclineButton onClick={declineCookies}>Refuser</DeclineButton>
        </ButtonGroup>
      </Popup>
    </PopupContainer>
  )
}

// Styled Components
const PopupContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 750px;
  background: ${({ theme }) => theme.tertiary}80;
  backdrop-filter: blur(5px);
  color: ${({ theme }) => theme.tertiary};
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-in-out;
  z-index: 999;
`

const Popup = styled.div`
  /* text-align: center; */
`

const PopupText = styled.p`
  font-size: 14px;
  margin-bottom: 12px;
  color: white;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

const AcceptButton = styled.button`
  background: ${({ theme }) => theme.tertiary};
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: rgb(71, 177, 114);
  }
`

const DeclineButton = styled.button`
  background: ${({ theme }) => theme.tertiary}50;
  color: white;
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: ${({ theme }) => theme.tertiary};
  }
`

export default CookiePopup
