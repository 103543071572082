import i18n from "i18next"
import HttpBackend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

// Read the language from the URL. Assumes URL format like "/fr/..."
const urlLang = typeof window !== "undefined" && window.location.pathname.split("/")[1]
const initialLang = ["en", "fr"].includes(urlLang) ? urlLang : "en"

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: initialLang, // force the initial language from the URL
    fallbackLng: "en",
    ns: ["home"],
    defaultNS: "home",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}/translation.json",
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
